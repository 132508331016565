const PLUS_MEMBER_CONFIG = {
  eufy: {
    countryList: ['us'],
  },
  anker: {
    countryList: ['us', 'checkoutex'],
  },
};

module.exports = {
  PLUS_MEMBER_CONFIG,
};
