/* eslint-disable no-param-reassign */
import { getApp } from '@/utils';
import { normalize } from '@/utils/metafield';
import { stores } from '@@/config';
import Cookies from 'js-cookie';

// start_ai_generated
const getRequestUrl = (app) => {
  const { shopify } = getApp(app);
  if (shopify.shopifyDomain) {
    return `https://${shopify.shopifyDomain}/api/2023-10/graphql.json`;
  }
  return `https://${app}.myshopify.com/api/2023-10/graphql.json`;
};
// end_ai_generated

const getCustomerRequestApi = (app) => {
  return '/api/storefront-customer-api';
};

export const getShippingAddress = ({ app, storeAccessToken, first = 10 }) => {
  return fetch(getCustomerRequestApi(), {
    method: 'POST',
    body: JSON.stringify({
      query: `
        query getCustomer($customerAccessToken: String!) {
          customer(customerAccessToken: $customerAccessToken) {
            defaultAddress {
              id
              firstName
              lastName
              address1
              address2
              province
              provinceCode
              city
              zip
              country
              countryCodeV2
              phone
            }
            addresses(first: ${first}, reverse: true) {
              nodes {
                id
                firstName
                lastName
                address1
                address2
                province
                provinceCode
                city
                zip
                country
                countryCodeV2
                phone
              }
              pageInfo {
                hasPreviousPage
                hasNextPage
                startCursor
                endCursor
              }
            }
          }
        }`,
      variables: {},
      app,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': storeAccessToken,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      const { customer } = res.data;
      const { defaultAddress, addresses } = customer;

      // start_ai_generated
      return {
        defaultAddress,
        addresses: addresses.nodes,
        pageInfo: addresses.pageInfo,
      };
      // end_ai_generated
    });
};

// start_ai_generated
export const createShippingAddress = ({ app, storeAccessToken, address }) => {
  return fetch(getCustomerRequestApi(), {
    method: 'POST',
    body: JSON.stringify({
      query: `
        mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
          customerAddressCreate(customerAccessToken: $customerAccessToken, address: $address) {
            customerAddress {
              id
              firstName
              lastName
              address1
              address2
              province
              city
              zip
              country
              phone
            }
            customerUserErrors {
              code
              field
              message
            }
          }
        }`,
      variables: {
        address,
      },
      app,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': storeAccessToken,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.errors) {
        throw new Error(res.errors[0].message);
      }

      const { customerAddress, customerUserErrors } = res.data.customerAddressCreate;

      if (customerUserErrors.length) {
        const error = new Error(customerUserErrors[0].message);
        error.code = customerUserErrors[0].code;
        throw error;
      }

      return customerAddress;
    });
};
// end_ai_generated

// start_ai_generated
export const setShippingAddressDefault = ({ app, storeAccessToken, addressId }) => {
  return fetch(getCustomerRequestApi(), {
    method: 'POST',
    body: JSON.stringify({
      query: `
        mutation customerDefaultAddressUpdate($customerAccessToken: String!, $addressId: ID!) {
          customerDefaultAddressUpdate(customerAccessToken: $customerAccessToken, addressId: $addressId) {
            customer {
              defaultAddress { 
                id
              }
            }
            customerUserErrors {
              code
              field
              message
            }
          }
        }`,
      variables: {
        addressId,
      },
      app,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': storeAccessToken,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.errors) {
        throw new Error(res.errors[0].message);
      }

      const { customerUserErrors } = res.data.customerDefaultAddressUpdate;

      if (customerUserErrors.length) {
        const error = new Error(customerUserErrors[0].message);
        error.code = customerUserErrors[0].code;
        throw error;
      }

      return true;
    });
};
// end_ai_generated

// start_ai_generated
export const deleteShippingAddress = ({ app, storeAccessToken, addressId }) => {
  return fetch(getCustomerRequestApi(), {
    method: 'POST',
    body: JSON.stringify({
      query: `
        mutation customerAddressDelete($customerAccessToken: String!, $id: ID!) {
          customerAddressDelete(customerAccessToken: $customerAccessToken, id: $id) {
            deletedCustomerAddressId
            customerUserErrors {
              code
              field
              message
            }
          }
        }`,
      variables: {
        id: addressId,
      },
      app,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': storeAccessToken,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.errors) {
        throw new Error(res.errors[0].message);
      }

      const { customerUserErrors } = res.data.customerAddressDelete;

      if (customerUserErrors.length) {
        const error = new Error(customerUserErrors[0].message);
        error.code = customerUserErrors[0].code;
        throw error;
      }

      return true;
    });
};
// end_ai_generated

// start_ai_generated
export const updateShippingAddress = ({ app, storeAccessToken, addressId, address }) => {
  return fetch(getCustomerRequestApi(), {
    method: 'POST',
    body: JSON.stringify({
      query: `
        mutation customerAddressUpdate($customerAccessToken: String!, $id: ID!, $address: MailingAddressInput!) {
          customerAddressUpdate(customerAccessToken: $customerAccessToken, id: $id, address: $address) {
            customerAddress {
              id
              firstName
              lastName
              address1
              address2
              province
              city
              zip
              country
              phone
            }
            customerUserErrors {
              code
              field
              message
            }
          }
        }`,
      variables: {
        id: addressId,
        address,
      },
      app,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': storeAccessToken,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.errors) {
        throw new Error(res.errors[0].message);
      }

      const { customerAddress, customerUserErrors } = res.data.customerAddressUpdate;

      if (customerUserErrors.length) {
        const error = new Error(customerUserErrors[0].message);
        error.code = customerUserErrors[0].code;
        throw error;
      }

      return customerAddress;
    });
};
// end_ai_generated

const metafieldsItems = `
  value
  type
  description
  namespace
  key
`;

const METAFIELD_NAMESPACE = process.env.ENVIRONMENT === 'development' ? 'app--5059485--combo' : 'app--5059775--combo';

const shopMetafieldIdentifiers = [
  { namespace: 'global', key: 'checkout_validator_info', name: 'checkoutValidatorInfo' },
  { namespace: 'global', key: 'checkout_address_info', name: 'checkoutAddressInfo' },
  { namespace: 'global', key: 'localeswitcher', name: 'localeSwitcherConfig' },
  { namespace: 'account', key: 'side_menu', name: 'sideMenu' },
  { namespace: 'account', key: 'header', name: 'header' },
  { namespace: 'account', key: 'footer', name: 'footer' },
  { namespace: 'account', key: 'customer_service', name: 'customerService' },
  { namespace: 'account', key: 'credit_task', name: 'creditTask' },
  { namespace: 'account', key: 'credit_info', name: 'creditInfo' },
  { namespace: 'account', key: 'code_info', name: 'codeInfo' },
  { namespace: 'account', key: 'announcement_bar', name: 'announcementBar' },
  { namespace: 'account', key: 'policies', name: 'policies' },
  { namespace: 'account', key: 'advertisement', name: 'advertisement' },
  { namespace: 'account', key: 'plusMember', name: 'plusMember' },
  { namespace: 'cookieConsent', key: 'settings', name: 'cookieConsentSettings' },
  { namespace: 'cookieConsent', key: 'disableBeforeConsent', name: 'disableBeforeConsent' },
  { namespace: 'member', key: 'setting', name: 'memberSetting' },
  { namespace: 'account', key: 'giftCard', name: 'giftCard' },
  { namespace: 'seo', key: 'setting', name: 'setting' },
  { namespace: 'seo', key: 'hidden', name: 'hidden' },
  { namespace: 'global', key: 'HideSearch', name: 'HideSearch' },
  { namespace: 'component', key: '_order', name: '_order' },
  { namespace: 'template', key: 'name', name: 'name' },
];

const normalizeMetafields = (metafields) => {
  // combo namespace 的优先级低于普通namespace的优先级
  return shopMetafieldIdentifiers?.reduce((prev, cur) => {
    const { namespace, key, name } = cur;

    const ms = metafields.find(({ namespace: ns, key: k }) => ns === namespace && k === key);

    const combMetafields = metafields.find(
      ({ namespace: ns, key: k }) => ns === METAFIELD_NAMESPACE && namespace === k,
    );

    if (!ms?.value && normalize(combMetafields)?.[key]) {
      const metafieldsUnderCurrentNamespace = normalize(combMetafields);

      prev[name] = normalize(metafieldsUnderCurrentNamespace[key]);
    } else {
      prev[name] = normalize(ms);
    }
    return prev;
  }, {});
};

export const getShopInfo = ({ app, storeAccessToken }) => {
  const comboNamespaceIdentifiers = shopMetafieldIdentifiers
    .filter((item, index, self) => {
      return index === self.findIndex((t) => t.namespace === item.namespace);
    })
    .map((item) => ({
      namespace: METAFIELD_NAMESPACE,
      key: item.namespace,
    }));

  const finalIdentifiers = comboNamespaceIdentifiers.concat(
    shopMetafieldIdentifiers.map((item) => ({ namespace: item.namespace, key: item.key })),
  );
  return fetch(getRequestUrl(app), {
    method: 'POST',
    body: JSON.stringify({
      query: `
        query getShopInfo($shopMetafieldIdentifiers: [HasMetafieldsIdentifier!] = []) {
          shop {
            name
            moneyFormat
            shipsToCountries
            metafields(identifiers: $shopMetafieldIdentifiers) {
             ${metafieldsItems}
            }
          }
        }`,
      variables: {
        shopMetafieldIdentifiers: finalIdentifiers,
      },
    }),

    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': storeAccessToken,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.errors) {
        throw new Error(res.errors[0].message);
      }

      const { shop } = res.data;

      const metafields = normalizeMetafields(shop.metafields.filter(Boolean));

      return {
        shop,
        shopMetafields: metafields,
      };
    });
};

const normalizeProduct = (product) => {
  return {
    ...product,
    variants: product.variants.nodes.map((variant) => ({
      ...variant,
      sellingPlanAllocations: variant.sellingPlanAllocations.edges.map(({ node }) => node),
    })),
  };
};

export const getProduct = ({ handle, app, storeAccessToken }) => {
  return fetch(getRequestUrl(app), {
    method: 'POST',
    body: JSON.stringify({
      query: `
        query GetProductByHandle($handle: String!) {
         product(handle: $handle) {
            id
            handle
            availableForSale
            title
            description
            descriptionHtml
            productType
            tags
            productType
            requiresSellingPlan
            variants(first: 10) {
              nodes {
                id
                sku
                title
                quantityAvailable
                currentlyNotInStock
                availableForSale
                barcode
                price {
                  amount
                  currencyCode
                }
                compareAtPrice {
                  amount
                  currencyCode
                }
                sellingPlanAllocations(first: 10) {
                  edges {
                    node {
                      sellingPlan{
                        id
                        name
                        priceAdjustments {
                          adjustmentValue {
                            ... on SellingPlanFixedAmountPriceAdjustment {
                              __typename
                              adjustmentAmount {
                                amount
                                currencyCode
                              }
                            }
                            ... on SellingPlanFixedPriceAdjustment {
                              __typename
                              price {
                                amount
                                currencyCode
                              }
                            }
                            ... on SellingPlanPercentagePriceAdjustment {
                              __typename
                              adjustmentPercentage
                            }
                          }
                        }
                        options {
                          name
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`,
      variables: { handle },
    }),

    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': storeAccessToken,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.errors) {
        throw new Error(res.errors[0].message);
      }
      const { product } = res.data;
      return {
        product: normalizeProduct(product),
      };
    });
};

export const getPageMetafields = ({ app, storeAccessToken, handle, metafieldIdentifiers }) => {
  return fetch(getRequestUrl(app), {
    method: 'POST',
    body: JSON.stringify({
      query: `
        query getPageInfoQuery($handle: String!, $identifiers: [HasMetafieldsIdentifier!] = []) {
          page(handle: $handle) {
            metafields(identifiers: $identifiers) {
              ${metafieldsItems}
            }
          }
        }`,
      variables: {
        handle,
        identifiers: metafieldIdentifiers,
      },
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-Shopify-Storefront-Access-Token': storeAccessToken,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.errors) {
        throw new Error(res.errors[0].message);
      }

      const { page } = res.data;
      if (!page) {
        return undefined;
      }

      const metafields = {};
      page.metafields
        .filter((item) => !!item)
        .forEach(({ namespace, key, ...other }) => {
          if (!metafields[namespace]) {
            metafields[namespace] = {};
          }
          metafields[namespace][key] = normalize(other);
        });

      return metafields;
    });
};
