export const normalize = (metafield) => {
  if (!metafield) {
    return undefined;
  }

  const { value, type, description } = metafield;

  const descriptionObj = JSON.parse(description || '{}');

  if (description && descriptionObj.status !== 'Active') {
    return undefined;
  }

  if (type === 'json') {
    return JSON.parse(value);
  }

  return value;
};
